/**
 *   Created by Nicolas Marchal on 08/06/2021
 *   Copyright © 2021 NM. All rights reserved.
 */

import {fetchUtils} from "react-admin";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  try {
    const {token} = JSON.parse(localStorage.getItem('auth'));
    options.headers.set('Authorization', `Bearer ${token}`);
  } catch (e) {
  }
  return fetchUtils.fetchJson(url, options);
};

export default httpClient;
