/**
 *   Created by Nicolas Marchal on 08/06/2021
 *   Copyright © 2021 NM. All rights reserved.
 */


const authProvider = {
  login: ({username, password}) => {
    const request = new Request('https://motion-concept.onadev.fr/api/v1/users/login', {
      method: 'POST',
      body: JSON.stringify({
        email: username,
        password: password
      }),
      headers: new Headers({'Content-Type': 'application/json'}),
    });
    return fetch(request)
      .then(async response => {
        if (response.status < 200 || response.status >= 300) {
          throw await response.json();
        }
        return response.json();
      })
      .then(auth => {
        localStorage.setItem('auth', JSON.stringify(auth));
      })
      .catch((e) => {
        console.log(e)
        if (e && e.description) {
          throw new Error(e.description)
        } else {
          throw new Error('Something wrong happened !')
        }
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('auth')
    ? Promise.resolve()
    : Promise.reject(),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const {id, email} = JSON.parse(localStorage.getItem('auth'));
      return Promise.resolve({id: id, fullName: email, avatar: null});
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const {roles} = JSON.parse(localStorage.getItem('auth')) | {};
    return roles ? Promise.resolve(roles) : Promise.reject();
  }

};

export default authProvider;
